/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider.state('refer-a-friend', {
    parent: 'root',
    url: '/refer-a-friend',
    templateUrl: 'sections/refer-a-friend/templates/acp-refer-a-friend.ng.html',
    controller: 'AcpReferAFriendSectionCtrl',
    data: {
      permissions: {
        only: 'oac4ReferAFriendEnabled',
        redirectTo: {
          oac4ReferAFriendEnabled: 'dashboard'
        }
      }
    },
    resolve: {
      module: /* @ngInject */ function($ocLazyLoad) {
        return import(/* webpackChunkName: "acp.section.refer-a-friend" */ 'sections/refer-a-friend').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}

export default states;
